import React, { useEffect, useState, memo } from 'react';
import { Card, Table, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import AddInvoiceDrawer from 'components/AddInvoiceDrawer/Index';
import { listInvoice, toggleDrawer, toggleDeleteModal } from './action';
import { InvoiceTableConfig } from './InvoiceTableConfig';
import InvoiceViewModal from 'components/InvoiceViewModal';

function Invoice() {
  useInjectReducer({ key: 'invoice', reducer });
  useInjectSaga({ key: 'invoice', saga });
  const [columns, setColumns] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const invoiceState = useSelector((state) => state.invoice || initialState);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = InvoiceTableConfig(viewInvoice);
    setColumns(columns);
    dispatch(listInvoice({ page: 1, limit: 10 }));
  }, []);

  const viewInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setOpenInvoice(!openInvoice);
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    dispatch(listInvoice({ page, limit: pageSize }));
  };

  return (
    <>
      <Card title="Invoices">
        <InvoiceViewModal
          invoice={selectedInvoice}
          open={openInvoice}
          viewInvoice={viewInvoice}
          invoiceState={invoiceState}
        />
        <Table
          columns={columns}
          loading={invoiceState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={invoiceState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={invoiceState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={invoiceState.loading}
          onChange={onPageChange}
        />
      </Card>
    </>
  );
}

export default Invoice;
