import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { message } from "antd";
import _ from "lodash";
import { authUserError } from "containers/AppLogin/action";
import {
  ADD_PROMOTION,
  EDIT_PROMOTION,
  LIST_PROMOTION,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
} from "./constant";
import {
  listPromotion as listPromotionAction,
  listPromotionSuccess,
  listPromotionError,
  addPromotionSuccess,
  addPromotionError,
  editPromotionSuccess,
  editPromotionError,
  toggleDrawer,
  toggleDeleteModal,
} from "./action";
import { addProvider, editProvider, listProvider } from "services/provider";

export function* listPromotionSaga(action) {
  try {
    const res = yield call(listProvider, action.payload);
    const promotionData = JSON.parse(res.text);
    // console.log(promotionData)

    yield put(
      listPromotionSuccess({
        attributes: promotionData.data.providers.map((item) => ({
          ...item,
          key: item.p_id,
        })),
      })
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listPromotionError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listPromotionError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error("Something went wrong");
    }
  }
}
export function* addPromotionSaga(action) {
  try {
    const res = yield call(addProvider, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addPromotionSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listPromotionAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addPromotionError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addPromotionError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addPromotionError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error("Something went wrong");
    }
  }
}

export function* editPromotionSaga(action) {
  try {
    const res = yield call(editProvider, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editPromotionSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listPromotionAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editPromotionError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editPromotionError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editPromotionError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error("Something went wrong");
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* PromotionSaga() {
  yield takeLatest(LIST_PROMOTION, listPromotionSaga);
  yield takeEvery(ADD_PROMOTION, addPromotionSaga);
  yield takeEvery(EDIT_PROMOTION, editPromotionSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
