import React, { useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Card, Row, Divider, Col } from "antd";
import loginLogo from "assets/Logo.png";
import { loginUser } from "./action";
import { useSelector, useDispatch } from "react-redux";
import { useInjectSaga } from "utils/injectSaga";
import saga from "./saga";
import _ from "lodash";

const AppLogin = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const userState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenCheck = localStorage.getItem("access_token");
    if (!_.isNil(tokenCheck)) {
      history.push("/dashboard");
    }
  }, []);

  const loginAppUser = () => {
    form.validateFields().then((values) => {
      dispatch(loginUser({ ...values, history }));
    });
  };

  useInjectSaga({ key: "login", saga });

  return (
    <Row justify="center" align="middle" style={{ height: "80vh" }}>
      <Card style={{ width: 600 }}>
        <Row justify="center" align="middle">
          <img src={loginLogo} style={{ width: "300px", height: "150px" }} />
        </Row>
        <Divider style={{ borderWidth: 2, borderColor: "#ddbc6b" }} />
        <Row justify="center" align="middle">
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{ remember: true }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                ]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={loginAppUser}
                  loading={userState.loading}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    background: "#ddbc6b",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default memo(AppLogin);
