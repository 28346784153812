import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import {
  createAdminUser,
  editAdminUser,
  listAdminUsers,
  deleteAdminUser,
  activeGender,
} from 'services/user';
import { authUserError } from 'containers/AppLogin/action';
import {
  LIST_USERS,
  ADD_USERS,
  EDIT_USERS,
  DELETE_USERS,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
  ACTIVE_ALL_GENDER,
  ACTIVE_ALL_GENDER_SUCCESS,
  ACTIVE_ALL_GENDER_ERROR,
} from './constant';
import {
  listUsers,
  listUsersSuccess,
  listUsersError,
  toggleDrawer,
  toggleDeleteModal,
  addUserSuccess,
  addUserError,
  editUserSuccess,
  editUserError,
  deleteUserSuccess,
  deleteUserError,
  activeAllGender,
  activeAllGenderSuccess,
  activeAllGenderError,
} from './action';

export function* listUsersSaga(action) {
  try {
    const res = yield call(listAdminUsers, action.payload);
    const userData = JSON.parse(res.text);
    yield put(
      listUsersSuccess({
        total: userData.data.total,
        users: userData.data.users.map((item) => ({ ...item, key: item.admin_user_id })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listUsersError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listUsersError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addUsersSaga(action) {
  try {
    const res = yield call(createAdminUser, action.payload);
    const userData = JSON.parse(res.text);
    yield put(toggleDrawer());
    yield put(addUserSuccess(userData.data));
    yield put(listUsers({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addUserError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addUserError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editUsersSaga(action) {
  try {
    const res = yield call(editAdminUser, action.payload);
    const userData = JSON.parse(res.text);
    yield put(toggleDrawer());
    yield put(editUserSuccess(userData.data));
    yield put(listUsers({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editUserError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editUserError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* deleteUsersSaga(action) {
  try {
    const res = yield call(deleteAdminUser, action.payload);
    const userData = JSON.parse(res.text);
    yield put(toggleDeleteModal());
    message.success(userData.data.message);
    yield put(listUsers({ page: 1, limit: 10 }));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(deleteUserError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(deleteUserError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(deleteUserError());
    }
    if (_.isEqual(err.status, 500)) {
      yield put(deleteUserError());
      message.error('Something went wrong');
    }
  }
}

export function* activeallGendersSaga(action) {
  try {
    const res = yield call(activeGender);
    const genderData = JSON.parse(res.text);
    yield put(activeAllGenderSuccess(genderData.data.allgenders));
    // console.log(genderData)
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(activeAllGenderError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(activeAllGenderError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
      yield put(activeAllGenderError(err.response.text));
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* roleSaga() {
  yield takeLatest(LIST_USERS, listUsersSaga);
  yield takeEvery(ADD_USERS, addUsersSaga);
  yield takeEvery(EDIT_USERS, editUsersSaga);
  yield takeEvery(DELETE_USERS, deleteUsersSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
  yield takeEvery(ACTIVE_ALL_GENDER, activeallGendersSaga);
}
