import * as Constant from './constant';

export const initialState = {
  totalCount: 0,
  data: [],
  cartList: [],
  selectedAttributesIds: [],
  activeallOrders: [],
  addonCartList: [],
  drawerData: [],
  drawerLoading: false,
  addonData: [],
  loading: false,
  error: false,
  drawer: false,
  deleteModal: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_ORDER:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.total,
        data: action.payload.orders,
        error: false,
      };
    case Constant.LIST_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
      case Constant.ACTIVE_ALL_ORDER:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case Constant.ACTIVE_ALL_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          activeallOrders: action.payload,
          error: false,
        };
      case Constant.ACTIVE_ALL_ORDER_ERROR:
        return {
          ...state,
          loading: false,
          activeallOrders: [],
          error: true,
        };
    case Constant.ADD_ORDER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        cartList: [],
        addonCartList: [],
      };
    case Constant.ADD_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.MARK_ORDER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.MARK_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.MARK_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DRAWER_DATA:
      return {
        ...state,
        drawerLoading: true,
        drawerData: [],
        error: false,
      };
    case Constant.DRAWER_DATA_SUCCESS:
      return {
        ...state,
        drawerLoading: false,
        drawerData: action.payload,
        error: false,
      };
    case Constant.DRAWER_DATA_ERROR:
      return {
        ...state,
        drawerLoading: false,
        drawerData: [],
        error: true,
      };
    case Constant.CATEGORY_ADDON:
      return {
        ...state,
        loading: true,
        addonData: [],
        error: false,
      };
    case Constant.CATEGORY_ADDON_SUCCESS:
      return {
        ...state,
        loading: false,
        addonData: action.payload,
        error: false,
      };
    case Constant.CATEGORY_ADDON_ERROR:
      return {
        ...state,
        loading: false,
        addonData: [],
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.CLEAR_CART_LIST:
      return {
        ...state,
        cartList: [],
        selectedAttributesIds: [],
        addonCartList: [],
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    case Constant.ADD_TO_CART:
      return {
        ...state,
        cartList: [...state.cartList, ...action.payload],
      };
    case Constant.REMOVE_FROM_CART:
      const cartList = state.cartList.filter(
        (cl) => !_.isEqual(cl.product_id, action.payload[0].product_id),
      );
      return {
        ...state,
        cartList,
      };
    case Constant.ADD_ADDON_TO_CART:
      const addonCartList = [...state.addonCartList, ...action.payload];
      const selectedAttributesIds = addonCartList.map((cl) => cl.attributeid);
      return {
        ...state,
        addonCartList: addonCartList,
        selectedAttributesIds,
      };
    case Constant.REMOVE_ADDON_FROM_CART:
      const addonCartListUpd = state.addonCartList.filter(
        (cl) => !_.isEqual(cl.attribute_id, action.payload.attribute_id),
      );
      const selectedAttributesIdsUpd = addonCartListUpd.map((cl) => cl.attributeid);
      return {
        ...state,
        addonCartList: addonCartListUpd,
        selectedAttributesIds: selectedAttributesIdsUpd,
      };
    default:
      return state;
  }
};

export default orderReducer;
