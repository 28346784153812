import * as Constant from './constant';

export const listUsers = (payload) => {
  return {
    type: Constant.LIST_USERS,
    payload,
  };
};

export const listUsersSuccess = (payload) => {
  return {
    type: Constant.LIST_USERS_SUCCESS,
    payload,
  };
};

export const listUsersError = () => {
  return {
    type: Constant.LIST_USERS_ERROR,
  };
};

export const addUser = (payload) => {
  return {
    type: Constant.ADD_USERS,
    payload,
  };
};

export const addUserSuccess = () => {
  return {
    type: Constant.ADD_USERS_SUCCESS,
  };
};

export const addUserError = () => {
  return {
    type: Constant.ADD_USERS_ERROR,
  };
};

export const editUser = (payload) => {
  return {
    type: Constant.EDIT_USERS,
    payload,
  };
};

export const editUserSuccess = () => {
  return {
    type: Constant.EDIT_USERS_SUCCESS,
  };
};

export const editUserError = () => {
  return {
    type: Constant.EDIT_USERS_ERROR,
  };
};

export const deleteUser = (payload) => {
  return {
    type: Constant.DELETE_USERS,
    payload,
  };
};

export const deleteUserSuccess = () => {
  return {
    type: Constant.DELETE_USERS_SUCCESS,
  };
};

export const deleteUserError = () => {
  return {
    type: Constant.DELETE_USERS_ERROR,
  };
};
export const activeAllGender = () => {
  return {
    type: Constant.ACTIVE_ALL_GENDER,
  };
};

export const activeAllGenderSuccess = (payload) => {
  return {
    type: Constant.ACTIVE_ALL_GENDER_SUCCESS,
    payload,
  };
};

export const activeAllGenderError = () => {s
  return {
    type: Constant.ACTIVE_ALL_GENDER_ERROR,
  };
};
export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
