import React, { useEffect, useState } from "react";
import { Card, Table, Pagination, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import reducer, { initialState } from "./reducer";
import saga from "./saga";
import _ from "lodash";
import { listPromotion, toggleDrawer } from "./action";
import { ProviderTableConfig } from "./ProviderTableConfig";
// import AddProviderDrawer from "components/AddProviderDrawer";

function Provider() {
  useInjectReducer({ key: "promotion", reducer });
  useInjectSaga({ key: "promotion", saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedPromotion, setselectedPromotion] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const promotionState = useSelector(
    (state) => state.promotion || initialState
  );
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = ProviderTableConfig(
      handleSearch,
      handleReset,
      // onDelete,
      editUserPromotion,
      authState.userResponsibilities
    );
    setColumns(columns);
    dispatch(listPromotion({ page: 1, limit: 10 }));
  }, []);

  // const onDelete = (item) => {
  //   if (!_.isNil(item)) {
  //     setselectedPromotion(item);
  //     dispatch(toggleDeleteModal());
  //     return;
  // };
  // dispatch(deleteQuote(selectedPromotion.quote_id));
  // console.log(item);
  // }
  const resetPagination = () => setCurrentPage(1);

  const editUserPromotion = (message) => {
    setselectedPromotion(message);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listPromotion(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error("Please select to search");
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(
      listPromotion({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] })
    );
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listPromotion({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Provider"
        // extra={
        //   <AddProviderDrawer
        //     initialState={initialState}
        //     selectedPromotion={selectedPromotion}
        //     resetPagination={resetPagination}
        //     editUserPromotion={editUserPromotion}
        //     roleAddResponsibility={_.includes(
        //       authState.userResponsibilities,
        //       "ADD_PROMOTION_BUTTON"
        //     )}
        //   />
        // }
      >
        <Table
          columns={columns}
          loading={promotionState.loading}
          bordered
          pagination={false}
          size={"small"}
          dataSource={promotionState.data}
          style={{ height: "70vh", overflow: "scroll" }}
        />
        <Pagination
          style={{ marginTop: "10px" }}
          total={promotionState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={promotionState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Quote Confirmation'}
        text={'Are you sure you want to delete this quote ?'}
        toggle={promotionState.deleteModal}
        loading={promotionState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default Provider;
