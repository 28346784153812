import React, { useEffect } from 'react';
import { Modal, Row, Col, Table, Divider, Skeleton, Button, Image } from 'antd';
import _ from 'lodash';
import 'App.css';
import { useDispatch } from 'react-redux';
import { invoiceDetail } from 'containers/Invoices/action';
import { markOrder } from 'containers/Orders/action';
import { OrderProductsTableConfig } from './OrderProductsTableConfig';
import Logo from 'assets/Logo.png';
import { listInvoiceSetting } from 'containers/InvoiceSettting/action';

function InvoiceViewModal({ invoice, open, viewInvoice, invoiceState }) {
  const dispatch = useDispatch();
  const prodCol = OrderProductsTableConfig();

  useEffect(() => {
    if (open) {
      dispatch(invoiceDetail({ order_id: invoice.order_id, invoice_id: invoice.invoice_id }));
      dispatch(listInvoiceSetting());
    }
  }, [open]);
  if (_.isEmpty(invoiceState.invoiceDetail)) {
    return null;
  }
  const ord_id = invoiceState.invoiceDetail.invoiceData.order_id;

  const markPaidOrder = () => {
    const product = { order_id: ord_id };
    dispatch(markOrder(product));
  };

  const { invoiceData } = invoiceState.invoiceDetail;
 
    return (
      <>
        <Modal
          title="Invoice"
          centered
          visible={open}
          onOk={() => viewInvoice(null)}
          onCancel={() => viewInvoice(null)}
          width={1000}
          destroyOnClose={true}
        >
          <br />

          <Skeleton loading={invoiceState.loading} active={invoiceState.loading}>
            <Row gutter={16}>
              <Col span={12}>
                <b style={{ fontSize: 20 }}>Invoice No: </b>
                <b style={{ fontSize: 20 }}>{invoiceData.invoice_no}</b>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row>
              <Col>
                <Image src={Logo} alt="image_invoice" style={{ height: '100px', width: '200px' }} />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                {/* {selectedInvoiceSetting?.values.map((d) => d.param_description)} */}
                {/* <b style={{ fontSize: 20 }}>Accounts Department</b>
            <h1 style={{ fontSize: 15 }}>
              79, P.E.C.H.S Block 2 Block 2 PECHS, Karachi, Karachi City, Sindh <br />
            </h1>
            <h4>Phone: +923023338677</h4> */}
              </Col>
              <Col span={12}></Col>
              <Col span={6}>
              
              <b style={{ fontSize: 15 }}>Client Name: </b>
                <b style={{ float: 'right', fontSize: 15 }}>{invoiceData.client_name}</b> <br />
              </Col>
              <Col span={6}>
                {/* <b style={{ fontSize: 15 }}>Reserved Amount: </b> */}
                {/* <b style={{ float: 'right', fontSize: 15 }}>{invoiceData.reserved_amount}</b> <br /> */}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b> Invoice Date:</b>{' '}
                  <span style={{ float: 'right' }}>{invoiceData.invoice_date}</span>
                </h1>
              </Col>
              <Col span={6}>
                {/* <h1 style={{ fontSize: 15 }}>
                  <b> Pro-Rated:</b>{' '}
                  <span style={{ float: 'right' }}> {invoiceData.is_prorated ? 'Yes' : 'No'}</span>
                </h1> */}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b> Due Date:</b> <span style={{ float: 'right' }}>{invoiceData.due_date}</span>
                </h1>
              </Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b> Payment Method:</b>{' '}
                  <span style={{ float: 'right' }}>{invoiceData.paymentmethod}</span>
                </h1>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b>Invoice Status:</b>{' '}
                  <span style={{ float: 'right' }}>{invoiceData.invoice_status}</span>
                </h1>
              </Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b>Discount:</b> <span style={{ float: 'right' }}>{invoiceData.discountvar}</span>
                </h1>
              </Col>
              {/* <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              <b>Remaining:</b> <span style={{ float: 'right' }}>{invoice.remaining_amount}</span>
            </h1>
          </Col> */}
            </Row>
            <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b> Renual Date:</b>{' '}
                  <span style={{ float: 'right' }}>{invoiceData.nextinvdate}</span>
                </h1>
              </Col>
              <Col span={6}>
                <h1 style={{ fontSize: 15 }}>
                  <b>Total Order:</b>{' '}
                  <span style={{ float: 'right' }}>{invoiceData.order_total}</span>
                </h1>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={6}></Col>
              <Col span={6}>
                {/* <h1 style={{ fontSize: 15 }}>
                  <b> Amount Payable:</b>{' '}
                  <span style={{ float: 'right' }}>{orderProducts[0].totalprice}</span>
                </h1> */}
              </Col>
              
            </Row>
            <br />
            {/* <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={12}>
                <Button
                  style={{ float: 'right', background: '#00916c', color: 'white' }}
                  onClick={markPaidOrder}
                >
                  Pay Now
                </Button>
              </Col>
            </Row> */}

            <br />
            <Divider>Order Services</Divider>
            <Row>
              <Col span={24}>
                <Table
                  columns={prodCol}
                  bordered
                  size={'small'}
                  dataSource={invoiceState.invoiceDetail.orderProducts}
                  style={{ margin: '10px' }}
                />
              </Col>
            </Row>
          </Skeleton>
        </Modal>
      </>
    );
  }

export default InvoiceViewModal;


