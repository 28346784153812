import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'containers/AppLogin/action';
import { LIST_ROLES, LIST_ROLES_RESPONSIBILITIES, ADD_ROLE_RESPONSIBILITIES } from './constant';
import {
  listRoleResponsibilitiesSuccess,
  listRoleResponsibilitiesError,
  addRoleResponsibilitiesSuccess,
  addRoleResponsibilitiesError,
  listRolesSuccess,
  listRolesError,
} from './action';
import {
  listUserRoleResponsibilities,
  addRoleResponsibilities,
  listUserRoles,
} from 'services/role';

export function* listRoleResponsibilitiesSaga(action) {
  try {
    const res = yield call(listUserRoleResponsibilities, action.payload);
    const userData = JSON.parse(res.text);
    yield put(listRoleResponsibilitiesSuccess(userData.data));
    // console.log(userData.data);
    // console.log('userData.data');
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listRoleResponsibilitiesError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listRoleResponsibilitiesError(err.response.text));
    }
  }
}

export function* addRoleResponsibilitiesSaga(action) {
  try {
    const res = yield call(addRoleResponsibilities, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addRoleResponsibilitiesSuccess(userData));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addRoleResponsibilitiesError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addRoleResponsibilitiesError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* listRolesSaga(action) {
  try {
    const res = yield call(listUserRoles);
    const userData = JSON.parse(res.text);
    yield put(listRolesSuccess(userData.data.roles.map((item) => ({ ...item, key: item.roleid }))));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listRolesError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listRolesError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* responsibilitySaga() {
  yield takeLatest(LIST_ROLES, listRolesSaga);
  yield takeEvery(LIST_ROLES_RESPONSIBILITIES, listRoleResponsibilitiesSaga);
  yield takeEvery(ADD_ROLE_RESPONSIBILITIES, addRoleResponsibilitiesSaga);
}
