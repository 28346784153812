export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PAYMENT_METHOD = ['Pay Pal','Cash', 'Bank Trasnfer', 'Credit Card',  'Pay Pro'];
export const SUBSCRIPTION_PERIOD = [
  
  '15 minutes',
  'N/A',
  '30 minutes',
  'N/A',
  'N/A',
  '60 minutes',
  'N/A',
  'N/A',
  'N/A',
  'N/A',
  'N/A',
  '120 minutes',
];
