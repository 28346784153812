export const LIST_USERS = 'LIST_USERS';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_ERROR = 'LIST_USERS_ERROR';

export const ADD_USERS = 'ADD_USERS';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_ERROR = 'ADD_USERS_ERROR';

export const EDIT_USERS = 'EDIT_USERS';
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_ERROR = 'EDIT_USERS_ERROR';

export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_ERROR = 'DELETE_USERS_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';

export const ACTIVE_ALL_GENDER = 'ACTIVE_GENDER';
export const ACTIVE_ALL_GENDER_SUCCESS = 'ACTIVE_GENDER_SUCCESS';
export const ACTIVE_ALL_GENDER_ERROR = 'ACTIVE_GENDER_ERROR';
