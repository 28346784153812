import { useState, memo } from "react";
import { Layout, Menu } from "antd";
import _ from "lodash";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";
import logo from "assets/logo_side.png";
import logoCrop from "assets/logo_side.png";
import "./Layout.css";
import {
  UserOutlined,
  ControlOutlined,
  UserAddOutlined,
  FileOutlined,
  DeliveredProcedureOutlined,
  UsergroupAddOutlined,
  DesktopOutlined,
  AntCloudOutlined,
  DollarOutlined,
  DashboardFilled,
  WechatOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

function AppNavigation(props) {
  const [collapsed, setCollapsed] = useState(false);
  if (props.loading) {
    return (
      <Sider
        theme="dark"
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{
          position: "relative",
          height: "100vh",
          backgroundcColor: "#ddbc6b",
        }}
      >
        <Loader
          type="ThreeDots"
          color="#ddbc6b"
          height={30}
          width={50}
          style={{ position: "absolute", top: "30%", left: "30%" }}
        />
      </Sider>
    );
  }

  return (
    <Sider
      theme="dark"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      // width={250}
    >
      <div
        style={{ textAlign: collapsed ? "center" : "left", padding: "10px" }}
      >
        <img
          src={collapsed ? logoCrop : logo}
          style={{ width: "148px", height: "40px" }}
        />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["0"]}
        mode="inline"
        style={{ background: "transparent" }}
      >
        {_.includes(props.userResponsibilities, "APP_DASHBOARD") ? (
          <Menu.Item key="0" icon={<DashboardFilled />}>
            <Link to={`/dashboard`}>Dashboard</Link>
          </Menu.Item>
        ) : (
          ""
        )}

        {_.includes(props.userResponsibilities, "ACL_OPEN_TAB") ? (
          <SubMenu key="sub1" icon={<ControlOutlined />} title="Team">
            {_.includes(
              props.userResponsibilities,
              "USER_OPEN_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="1" icon={<UserAddOutlined />}>
                <Link to={`/users`}>Users</Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {/* {_.includes(
              props.userResponsibilities,
              "ACL_ROLE_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="2" icon={<PicRightOutlined />}>
                <Link to={`/role`}>Roles</Link>
              </Menu.Item>
            ) : (
              ""
            )}

            {_.includes(
              props.userResponsibilities,
              "ACL_RESPONSIBILITIES_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="3" icon={<ApiOutlined />}>
                <Link to={`/responsibilities`}>Responsibilities</Link>
              </Menu.Item>
            ) : (
              ""
            )} */}
          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "USER_OPEN_TAB") ? (
          <SubMenu key="sub2" icon={<UserOutlined />} title="Customer Setting">
            {_.includes(
              props.userResponsibilities,
              "USER_ORDER_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="4" icon={<DeliveredProcedureOutlined />}>
                <Link to={`/orders`}>Bookings</Link>
              </Menu.Item>
            ) : (
              ""
            )}

            {_.includes(
              props.userResponsibilities,
              "USER_INVOICE_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="7" icon={<FileOutlined />}>
                <Link to={`/invoices`}>Invoices</Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(
              props.userResponsibilities,
              "USER_CLIENT_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="8" icon={<UsergroupAddOutlined />}>
                <Link to={`/clients`}>Client</Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "ADD_PROVIDER_SUBTAB") ? (
              <Menu.Item key="40" icon={<UsergroupAddOutlined />}>
                <Link to={`/providers`}>Providers</Link>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}

        {_.includes(props.userResponsibilities, "PRODUCT_OPEN_TAB") ? (
          <SubMenu key="sub6" icon={<AntCloudOutlined />} title="Service">
            {_.includes(
              props.userResponsibilities,
              "PRODUCT_CATEGORY_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="14" icon={<DesktopOutlined />}>
                <Link to={`/categories`}>Categories</Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(
              props.userResponsibilities,
              "PRODUCT_PROD_MANAGE_SUBTAB"
            ) ? (
              <Menu.Item key="15" icon={<DollarOutlined />}>
                <Link to={`/Service`}>Service</Link>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "SETTING_OPEN_TAB") ? (
          <SubMenu key="sub7" icon={<ControlOutlined />} title="Settings">
            {_.includes(
              props.userResponsibilities,
              "SETTING_INVOICE_SUBTAB"
            ) ? (
              <Menu.Item key="17" icon={<UserAddOutlined />}>
                <Link to={`/invoicesettings`}>Invoice </Link>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "MARKETING_OPEN_TAB") ? (
          <SubMenu key="sub8" icon={<ControlOutlined />} title="Marketing">
            {_.includes(props.userResponsibilities, "MESSAGE_SUBTAB") ? (
              <Menu.Item key="24" icon={<WechatOutlined />}>
                <Link to={`/message`}>Message</Link>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
      </Menu>
    </Sider>
  );
}

export default memo(AppNavigation);
