import * as Constant from './constant';

export const clientState = () => {
  return {
    type: Constant.CLIENT_STATS,
  };
};

export const clientStatsSuccess = (payload) => {
  return {
    type: Constant.CLIENT_STATS_SUCCESS,
    payload,
  };
};

export const clientStatsError = () => {
  return {
    type: Constant.CLIENT_STATS_ERROR,
  };
};
