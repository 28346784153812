import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'containers/AppLogin/action';
import { listCategory, addCategory, editCategory, activeCategory } from 'services/category';
import {
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  LIST_CATEGORY,
  ACTIVE_CATEGORY,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
} from './constant';
import {
  listCategorySuccess,
  listCategoryError,
  toggleDrawer,
  toggleDeleteModal,
  addCategorySuccess,
  addCategoryError,
  editCategorySuccess,
  editCategoryError,
  activeCategorySuccess,
  activeCategoryError,
  deleteCategorySuccess,
  deleteCategoryeError,
} from './action';

export function* listCategorySaga(action) {
  try {
    const res = yield call(listCategory);
    const userData = JSON.parse(res.text);
    // console.log(userData)
    yield put(
      listCategorySuccess(userData.data.categories.map((item, ind) => ({ ...item, key: ind }))),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listCategoryError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listCategoryError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* activeCategorySaga(action) {
  try {
    const res = yield call(activeCategory);
    const categoryData = JSON.parse(res.text);
    // console.log(categoryData)
    yield put(activeCategorySuccess(categoryData.data.categories));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(activeCategoryError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(activeCategoryError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
      yield put(activeCategoryError(err.response.text));
    }
  }
}

export function* addCategorySaga(action) {
  try {
    const res = yield call(addCategory, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addCategorySuccess(userData.data));
    message.success(userData.data.message);
    yield put(toggleDrawer());
    yield put(listCategory());
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addCategoryError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addCategoryError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addCategoryError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editCategorySaga(action) {
  try {
    const res = yield call(editCategory, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editCategorySuccess(userData.data));
    yield put(toggleDrawer());
    yield call(listCategorySaga);
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editCategoryError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editCategoryError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editCategoryError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* deleteCategorySaga(action) {
  try {
    const res = yield call(deleteCategory, action.payload);
    const userData = JSON.parse(res.text);
    yield put(toggleDeleteModal());
    yield put(deleteCategorySuccess(userData));
    yield call(listCategorySaga);
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(deleteCategoryError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(deleteCategoryError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* categorySaga() {
  yield takeLatest(LIST_CATEGORY, listCategorySaga);
  yield takeEvery(ADD_CATEGORY, addCategorySaga);
  yield takeEvery(EDIT_CATEGORY, editCategorySaga);
  yield takeEvery(ACTIVE_CATEGORY, activeCategorySaga);
  // yield takeEvery(DELETE_CATEGORY, deleteRolesSaga);
  // yield takeEvery(TOGGLE_CATEGORY, toggleDrawerSaga);
  // yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
