import _ from 'lodash';
const InvoiceStatus = ['Unpaid', 'Paid', 'Deferred', 'Completed', 'Due Date Crossed'];

export const InvoiceTableDashboardConfig = () => {
  return [
    {
      title: 'Invoice ID',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      width: '2%',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '3%',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '3%',
      render: (invoice_date) => {
        return <div>{invoice_date ? invoice_date.split('T')[0] : 'N/A'}</div>;
      },
    },
    {
      title: 'Staus',
      dataIndex: 'invoice_status_id',
      key: 'invoice_status_id',
      width: '3%',
      render: (invoice_status_id) => InvoiceStatus[parseInt(invoice_status_id)],
    },
    {
      title: 'Total',
      dataIndex: 'order_total',
      key: 'order_total',
      width: '3%',
    },

    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      width: '3%',
      render: (due_date) => {
        return <div>{due_date ? due_date.split('T')[0] : 'N/A'}</div>;
      },
    },
  ];
};
