import { Table, Input, Button, Space, Tag } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import _ from "lodash";

export const ProviderTableConfig = (
  handleSearch,
  handleReset,
  // onDelete,
  editUserPromotion,
  responsibilities
) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    };
  };
  return [
    {
      title: "Name",
      dataIndex: "p_first_name",
      key: "p_first_name",
      width: "4%",
    },

    {
      title: "Email",
      dataIndex: "emial",
      key: "emial",
      width: "4%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "4%",
    },
    {
      title: "Social Number",
      dataIndex: "social_number",
      key: "social_number",
      width: "4%",
    },
    {
      title: "Home",
      dataIndex: "home_add",
      key: "home_add",
      width: "4%",
    },

    {
      title: "Status",
      dataIndex: "isactive",
      key: "isactive",
      width: "5%",
      render: (status) => {
        return (
          <Tag color={status ? "green" : "red"}>
            {status ? "Active" : "In Active"}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: "2%",
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <EyeOutlined
                style={{
                  color: "#0D8616",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
              {/* {_.includes(responsibilities, "EDIT_PROVDER_BUTTON") ? (
                <EditOutlined
                  style={{
                    color: "#0D4B86",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => editUserPromotion(item)}
                />
              ) : (
                ""
              )} */}
              {/* {_.includes(responsibilities, 'DELETE_MESSAGE_BUTTON') ? (
                <DeleteOutlined
                  style={{ color: '#F52A0E', cursor: 'pointer', fontSize: '20px' }}
                  onClick={() => onDelete(item)}
                />
              ) : (
                ''
              )} */}
            </Space>
          </div>
        );
      },
    },
  ];
};
