import { SUBSCRIPTION_PERIOD } from 'utils/constants';

export const OrderProductsTableConfig = () => {
  return [
    // {
    //   title: 'Category Name',
    //   dataIndex: 'categoryname',
    //   key: 'categoryname',
    //   width: '30%',
    // },
    {
      title: 'Product Name',
      dataIndex: 'productname',
      key: 'productname',
      width: '30%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
    },
    {
      title: 'Duration',
      dataIndex: 'subscription_length',
      key: 'subscription_length',
      width: '30%',
      render: (subscription_length) => <>{subscription_length} Minutes</>,
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitprice',
      key: 'unitprice',
      width: '30%',
    },
    {
      title: 'Total Price',
      dataIndex: 'totalprice',
      key: 'totalprice',
      width: '30%',
    },
  ];
};
