import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listUserRoles = async () => {
  return await superagent
    .get(`${BASE_URL}/role/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};

export const listUserRoleResponsibilities = async (roleid) => {
  return await superagent
    .get(`${BASE_URL}/role/responsibilities/${roleid}`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};

export const addRole = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/role/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const addRoleResponsibilities = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/role/responsibilities`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editRole = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/role/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const deleteRole = async (roleid) => {
  return await superagent
    .delete(`${BASE_URL}/role/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .send({ roleid })
    .retry(2);
};
