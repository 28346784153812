import React, { useEffect, useState, memo } from 'react';
import { Card, Table, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import AddOrderDrawer from 'components/AddOrderDrawer/Index';
import { listOrder, toggleDrawer, toggleDeleteModal } from './action';
import { OrderTableConfig } from './OrderTableConfig';

function Order() {
  useInjectReducer({ key: 'order', reducer });
  useInjectSaga({ key: 'order', saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedOrder, setselectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const orderState = useSelector((state) => state.order || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = OrderTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editOrder,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listOrder({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => {};

  const resetPagination = () => {
    setCurrentPage(1);
  };

  const editOrder = (order) => {
    setselectedOrder(order);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    dispatch(listOrder({ page, limit: pageSize }));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  return (
    <>
      <Card
        title="Orders"
        extra={
          <AddOrderDrawer
            initialState={initialState}
            selectedOrder={selectedOrder}
            resetPagination={resetPagination}
            editOrder={editOrder}
            orderState={orderState}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'USER_ORDER_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={orderState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={orderState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={orderState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={orderState.loading}
          onChange={onPageChange}
        />
      </Card>
    </>
  );
}

export default Order;
