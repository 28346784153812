import { Input, Button, Space, Tag } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const UsersTableConfig = (
  handleSearch,
  handleReset,
  onDelete,
  editAdminUser,
  responsibilities,
) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '10%',
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '10%',
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      dataIndex: 'rolename',
      key: 'rolename',
      width: '10%',
      ...getColumnSearchProps('rolename'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      ...getColumnSearchProps('status'),
      render: (status) => (
        <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'In Active'}</Tag>
      ),
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '5%',
      render: (user) => (
        <div>
          <Space size="middle">
            <EyeOutlined style={{ color: '#0D8616', cursor: 'pointer', fontSize: '20px' }} />
            {_.includes(responsibilities, 'USER_EDIT_BUTTON') ? (
              <EditOutlined
                style={{ color: '#0D4B86', cursor: 'pointer', fontSize: '20px' }}
                onClick={() => editAdminUser(user)}
              />
            ) : (
              ''
            )}
            {_.includes(responsibilities, 'USER_DELETE_BUTTON') ? (
              <DeleteOutlined
                style={{ color: '#F52A0E', cursor: 'pointer', fontSize: '20px' }}
                onClick={() => onDelete(user)}
              />
            ) : (
              ''
            )}
          </Space>
        </div>
      ),
    },
  ];
};
