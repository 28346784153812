import React, { useEffect, useState, memo } from "react";
import { Card, Select, Button, Row, Col, Typography, message } from "antd";
import TableTransfer from "components/TableTransfer";
import reducer, { initialState } from "./reducer";
import { listRoles } from "./action";
import userRoleReducer, {
  initialState as roleInitialState,
} from "containers/Role/reducer";
import roleSaga from "containers/Role/saga";
import saga from "./saga";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import { listRoleResponsibilities, addRoleResponsibilities } from "./action";

const { Option } = Select;
const { Title } = Typography;

const leftTableColumns = [
  {
    dataIndex: "displayname",
    title: "Name",
  },
];
const rightTableColumns = [
  {
    dataIndex: "displayname",
    title: "Name",
  },
];

function Responsibilities() {
  useInjectReducer({ key: "responsibility", reducer });
  useInjectSaga({ key: "responsibility", saga });
  const dispatch = useDispatch();
  const roleResponsibility = useSelector(
    (state) => state.responsibility || initialState
  );
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [targetKeys, setTargetKeys] = useState(
    roleResponsibility.userRoleResponsibilities.map(
      (res) => res.responsibilityid
    )
  );
  const [unattachedResponsibilities, setUnattachedResponsibilities] = useState(
    []
  );
  const dataSource = roleResponsibility.superAdminResponsibilities.map(
    (item) => ({
      ...item,
      key: item.responsibilityid,
    })
  );
  useEffect(() => {
    dispatch(listRoles());
  }, []);

  useEffect(() => {
    setTargetKeys(
      _.map(roleResponsibility.userRoleResponsibilities, "responsibilityid")
    );
  }, [roleResponsibility.userRoleResponsibilities]);

  useEffect(() => {
    setRoles(roleResponsibility.data);
  }, [roleResponsibility.data]);

  const handleChange = (value) => {
    setSelectedRole(value);
    dispatch(listRoleResponsibilities(value));
  };

  const onChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
    if (_.isEqual(direction, "left")) {
      const newKeys = unattachedResponsibilities.filter(
        (key) => !_.includes(moveKeys, key)
      );
      setUnattachedResponsibilities(newKeys);
    } else if (_.isEqual(direction, "right")) {
      setUnattachedResponsibilities([
        ...moveKeys,
        ...unattachedResponsibilities,
      ]);
    }
  };
  const attachResponsibility = () => {
    const payload = targetKeys.map((key) => ({
      roleid: parseInt(selectedRole),
      responsibilityid: parseInt(key),
    }));
    dispatch(
      addRoleResponsibilities({
        roleid: parseInt(selectedRole),
        responsibilities: payload,
      })
    );
  };
  return (
    <Card title="Role">
      <Row>
        <Col span={3}>
          <Title level={4}>Select Role :</Title>
        </Col>
        <Col span={20}>
          <Select
            size={"large"}
            onChange={handleChange}
            disabled={roleResponsibility.loading}
            loading={roleResponsibility.loading}
            style={{ width: "100%" }}
          >
            {roles.map((role) => (
              <Option key={role.roleid}>{role.rolename}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={20} offset={3}>
          <TableTransfer
            dataSource={dataSource}
            targetKeys={targetKeys}
            titles={[
              "Unattached Responsibilities",
              "Attached Responsibilities",
            ]}
            disabled={false}
            loading={roleResponsibility.loading}
            onChange={onChange}
            filterOption={(inputValue, item) => {}}
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
          />
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={3} style={{ marginTop: "20px" }} gutter={10}>
          <Button
            style={{
              backgroundColor: "#ddbc6b",
              borderColor: "#ddbc6b",
              color: "white",
            }}
            size="medium"
            onClick={() => attachResponsibility()}
          >
            Attach
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default memo(Responsibilities);
