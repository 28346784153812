import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';

import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const loginAppUser = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/auth/login`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .redirects(0)
    .retry(2)
    .send(payload);
};

export const getAuthUser = async () => {
  return await superagent
    .get(`${BASE_URL}/auth/me`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .redirects(0)
    .retry(2);
};
