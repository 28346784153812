import * as Constant from './constant';

export const listRoles = (payload) => {
  return {
    type: Constant.LIST_ROLES,
    payload,
  };
};

export const listRolesSuccess = (payload) => {
  return {
    type: Constant.LIST_ROLES_SUCCESS,
    payload,
  };
};

export const listRolesError = () => {
  return {
    type: Constant.LIST_ROLES_ERROR,
  };
};

export const listRoleResponsibilities = (payload) => {
  return {
    type: Constant.LIST_ROLES_RESPONSIBILITIES,
    payload,
  };
};

export const listRoleResponsibilitiesSuccess = (payload) => {
  return {
    type: Constant.LIST_ROLES_RESPONSIBILITIES_SUCCESS,
    payload,
  };
};

export const listRoleResponsibilitiesError = () => {
  return {
    type: Constant.LIST_ROLES_RESPONSIBILITIES_ERROR,
  };
};

export const addRoleResponsibilities = (payload) => {
  return {
    type: Constant.ADD_ROLE_RESPONSIBILITIES,
    payload,
  };
};

export const addRoleResponsibilitiesSuccess = (payload) => {
  return {
    type: Constant.ADD_ROLE_RESPONSIBILITIES_SUCCESS,
    payload,
  };
};

export const addRoleResponsibilitiesError = () => {
  return {
    type: Constant.ADD_ROLE_RESPONSIBILITIES_ERROR,
  };
};
