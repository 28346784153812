import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Table, Card, Pagination } from 'antd';
import { UsersTableConfig } from './UsersTableConfig';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import AddUserDrawer from 'components/AddUserDrawer';
import ConfirmModal from 'components/ConfirmModal';
import {
  listUsers,
  toggleDeleteModal, 
  toggleDrawer,
  addUser,
  editAdminUser,
  deleteUser,
} from './action';
import { listRoles } from '../Role/action';
import reducer, { initialState } from './reducer';
import userRoleReducer, { initialState as roleInitialState } from '../Role/reducer';
import saga from './saga';
import roleSaga from '../Role/saga';

function Users(props) {
  useInjectReducer({ key: 'role', reducer: userRoleReducer });
  useInjectSaga({ key: 'role', saga: roleSaga });
  useInjectReducer({ key: 'user', reducer });
  useInjectSaga({ key: 'user', saga });
  const [columns, setColumns] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const userState = useSelector((state) => state.user || initialState);
  const roleState = useSelector((state) => state.role || roleInitialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = UsersTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editAdminUser,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listRoles());
    dispatch(listUsers({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => {
    if (!_.isNil(item)) {
      console.log(item);
      setSelectedUser(item);
      dispatch(toggleDeleteModal());
      return;
    }
    dispatch(deleteUser({ user_id: selectedUser.admin_user_id }));
  };

  const editAdminUser = (user) => {
    setSelectedUser(user);
    dispatch(toggleDrawer());
  };

  const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onPageChange = (page, pageSize) => {
    dispatch(listUsers({ page, limit: pageSize }));
  };
  return (
    <>
      <Card
        title="Admin Users"
        extra={
          <AddUserDrawer
            initialState={initialState}
            selectedUser={selectedUser}
            roles={roleState.data}
            editAdminUser={editAdminUser}
            userAddResponsibility={_.includes(authState.userResponsibilities, 'USER_ADD_BUTTON')}
          />
        }
      >
        <Table
          columns={columns}
          bordered
          size={'small'}
          loading={userState.loading || roleState.loading}
          dataSource={userState.data}
          pagination={false}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={userState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          defaultCurrent={1}
          showLessItems={true}
          showSizeChanger={false}
          disabled={userState.loading}
          onChange={onPageChange}
        />
      </Card>
      <ConfirmModal
        title={'Delete User Confirmation'}
        text={'Are you sure you want to delete this user ?'}
        toggle={userState.deleteModal}
        loading={userState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      />
    </>
  );
}

export default Users;