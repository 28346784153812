import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'containers/AppLogin/action';
import { listInvoice, addInvoice, getInvoiceDetail } from 'services/invoice';
import {
  ADD_INVOICE,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
  LIST_INVOICE,
  INVOICE_DETAIL,
} from './constant';
import {
  addInvoiceSuccess,
  addInvoiceError,
  toggleDrawer,
  toggleDeleteModal,
  listInvoiceSuccess,
  listInvoiceError,
  invoiceDetailSuccess,
  invoiceDetailError,
} from './action';

export function* addInvoiceSaga(action) {
  try {
    const res = yield call(addInvoice, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addInvoiceSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listInvoiceAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addInvoiceError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addInvoiceError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addInvoiceError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* listInvoiceSaga(action) {
  try {
    const res = yield call(listInvoice, action.payload);
    const invoiceData = JSON.parse(res.text);
    // console.log(invoiceData)
    yield put(listInvoiceSuccess(invoiceData.data));
  } catch (err) {
    // console.log(err);
    if (_.isEqual(err.status, 401)) {
      yield put(listInvoiceError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(listInvoiceError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listInvoiceError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* getInvoiceDetailSaga(action) {
  try {
    const res = yield call(getInvoiceDetail, action.payload);
    const invoiceData = JSON.parse(res.text);
    // console.log(invoiceData)
    yield put(invoiceDetailSuccess(invoiceData.data));
  } catch (err) {
    // console.log(err);
    if (_.isEqual(err.status, 401)) {
      yield put(invoiceDetailError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(invoiceDetailError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(invoiceDetailError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* roleSaga() {
  yield takeEvery(LIST_INVOICE, listInvoiceSaga);
  yield takeEvery(ADD_INVOICE, addInvoiceSaga);
  yield takeEvery(INVOICE_DETAIL, getInvoiceDetailSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
