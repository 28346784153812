import * as Constant from './constant';

export const initialState = {
  totalCount: 0,
  data: [],
  activeallGenders: [],
  loading: false,
  error: false,
  drawer: false,
  deleteModal: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_USERS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
      };
    case Constant.LIST_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.users,
        totalCount: action.payload.total,
        error: false,
      };
    case Constant.LIST_USERS_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        totalCount: 0,
        error: true,
      };
    case Constant.ADD_USERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_USERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DELETE_USERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.ACTIVE_ALL_GENDER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ACTIVE_ALL_GENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        activeallGenders: action.payload,
        error: false,
      };
    case Constant.ACTIVE_ALL_GENDER_ERROR:
      return {
        ...state,
        loading: false,
        activeallGenders: [],
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default usersReducer;
