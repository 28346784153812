import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from 'containers/Invoices/reducer';
import saga from 'containers/Invoices/saga';
import { listInvoice } from 'containers/Invoices/action';
import InvoiceViewModal from 'components/InvoiceViewModal';
import { InvoiceTableDashboardConfig } from '.';

const GREY = '#9E9E9E';

function ListInvoiceDashboard() {
  useInjectReducer({ key: 'invoice', reducer });
  useInjectSaga({ key: 'invoice', saga });
  const [columns, setColumns] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(false);
  const invoiceState = useSelector((state) => state.invoice || initialState);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = InvoiceTableDashboardConfig(viewInvoice);
    setColumns(columns);
    dispatch(listInvoice({ page: 1, limit: 10 }));
  }, []);

  const viewInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setOpenInvoice(!openInvoice);
  };

  return (
    <>
      <Card title="Invoices"
       style={{
        borderRadius: '12px',
        width: '100%',
        height: '480px',
        backgroundColor: 'white',
        fontSize: 20,
        color: '#2a2a2a',
        boxShadow: `1px 3px 10px ${GREY}`,
        overflow: 'scroll'
      }}>
        <InvoiceViewModal
          invoice={selectedInvoice}
          open={openInvoice}
          viewInvoice={viewInvoice}
          invoiceState={invoiceState}
        />
        <Table
          columns={columns}
          loading={invoiceState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={invoiceState.data}
          style={{ height: '55vh' }}
        />
      </Card>
    </>
  );
}

export default ListInvoiceDashboard;
