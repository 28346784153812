import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { listUserRoles, addRole, editRole, deleteRole } from 'services/role';
import { authUserError } from 'containers/AppLogin/action';
import {
  ADD_ROLE,
  EDIT_ROLE,
  DELETE_ROLE,
  LIST_ROLES,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
} from './constant';
import {
  listRolesSuccess,
  listRolesError,
  toggleDrawer,
  toggleDeleteModal,
  addRoleSuccess,
  addRoleError,
  editRoleSuccess,
  editRoleError,
  deleteRoleSuccess,
  deleteRoleError,
} from './action';

export function* listRolesSaga(action) {
  try {
    const res = yield call(listUserRoles);
    const userData = JSON.parse(res.text);
    yield put(listRolesSuccess(userData.data.roles.map(item => ({ ...item, key: item.roleid }))));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listRolesError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listRolesError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addRolesSaga(action) {
  try {
    const res = yield call(addRole, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addRoleSuccess(userData.data));
    yield put(toggleDrawer());
    yield call(listRolesSaga);
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addRoleError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(deleteRoleError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addRoleError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editRolesSaga(action) {
  try {
    const res = yield call(editRole, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editRoleSuccess(userData.data));
    yield put(toggleDrawer());
    yield call(listRolesSaga);
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editRoleError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editRoleError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(deleteRoleError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* deleteRolesSaga(action) {
  try {
    const res = yield call(deleteRole, action.payload);
    const userData = JSON.parse(res.text);
    yield put(toggleDeleteModal());
    yield put(deleteRoleSuccess(userData));
    yield call(listRolesSaga);
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(deleteRoleError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(deleteRoleError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* roleSaga() {
  yield takeLatest(LIST_ROLES, listRolesSaga);
  yield takeEvery(ADD_ROLE, addRolesSaga);
  yield takeEvery(EDIT_ROLE, editRolesSaga);
  yield takeEvery(DELETE_ROLE, deleteRolesSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
