import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import loginUserReducer from 'containers/AppLogin/reducer';

import history from 'utils/history';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: loginUserReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
