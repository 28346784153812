import React, { useEffect, useState, memo } from 'react';
import { Card, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import AddRoleDrawer from 'components/AddRoleDrawer';
import ConfirmModal from 'components/ConfirmModal';
import { RoleTableConfig } from './RoleTableConfig';
import { listRoles, deleteRole, toggleDrawer, toggleDeleteModal } from './action';

function Role() {
  useInjectReducer({ key: 'role', reducer });
  useInjectSaga({ key: 'role', saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const roleState = useSelector((state) => state.role || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = RoleTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editUserRole,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listRoles());
  }, []);

  const onDelete = (item) => {
    if (!_.isNil(item)) {
      setSelectedRole(item);
      dispatch(toggleDeleteModal());
      return;
    }
    dispatch(deleteRole(selectedRole.roleid));
  };

  const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const editUserRole = (role) => {
    setSelectedRole(role);
    dispatch(toggleDrawer());
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  return (
    <>
      <Card
        title="System Roles"
        extra={
          <AddRoleDrawer
            initialState={initialState}
            selectedRole={selectedRole}
            editUserRole={editUserRole}
            roleAddResponsibility={_.includes(authState.userResponsibilities, 'ROLE_ADD_BUTTON')}
          />
        }
      >
        <Table
          columns={columns}
          loading={roleState.loading}
          bordered
          size={'small'}
          dataSource={roleState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
      </Card>
      <ConfirmModal
        title={'Delete Role Confirmation'}
        text={'Are you sure you want to delete this role ?'}
        toggle={roleState.deleteModal}
        loading={roleState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      />
    </>
  );
}

export default Role;
