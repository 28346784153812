import { useEffect, memo } from 'react';
import { Layout } from 'antd';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import { authUser } from 'containers/AppLogin/action';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/AppLogin/saga';
import { useSelector, useDispatch } from 'react-redux';
import AppNavigation from 'components/AppNavigation';
import AppHeader from 'components/AppHeader';
import SessionModal from 'components/SessionModal';

const { Content } = Layout;

function AppLayout(props) {
  useInjectSaga({ key: 'login', saga });

  const userState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(userState.user) && !_.isNil(localStorage.getItem('access_token'))) {
      dispatch(authUser());
    }
  }, []);
  return (
    <LoadingOverlay active={userState.loading} spinner text="Please wait...">
      <SessionModal />
      <Layout style={{ minHeight: '100vh' }}>
        <AppNavigation
          user={userState.user}
          loading={userState.loading}
          userResponsibilities={userState.userResponsibilities}
        />
        <Layout>
          <AppHeader />
          <div>
            <Content className="site-content">{props.children}</Content>
          </div>
        </Layout>
      </Layout>
    </LoadingOverlay>
  );
}

export default memo(AppLayout);
