import * as Constant from './constant';

export const initialState = {
  data: [],
  activeCategories: [],
  loading: false,
  error: false,
  drawer: false,
  deleteModal: false,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_CATEGORY:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case Constant.LIST_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.ADD_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.ACTIVE_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ACTIVE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        activeCategories: action.payload,
        error: false,
      };
    case Constant.ACTIVE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        activeCategories: [],
        error: true,
      };
    case Constant.DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default categoryReducer;