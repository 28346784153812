import { Row, Col } from "antd";
import Statisticss from "components/DashboardStatics";
import ListInvoiceDashboard from "components/InvoiceDashboardView/ListInvoiceDashboard";
import React from "react";

function Dashboard(props) {
  return (
    <div className="site-layout-background">
      <div>
        <Statisticss />
        {/* <Radar/> */}

        <Row style={{ padding: "12px" }} gutter={[16, 16]}>
          <Col xs={24} md={24}>
            <ListInvoiceDashboard />
          </Col>
          {/* <Col xs={24} md={8}>
            <Card
              style={{
                borderRadius: '12px',
                width: '100%',
                height: '480px',
                backgroundColor: 'transparent',
                fontSize: 20,
                color: '#2a2a2a',
                boxShadow: `1px 3px 10px ${GREY}`,
              }}
              className="box"
            >
              <strong>Current Month Sale</strong>
              <div
                style={{
                  width: 'auto',
                  height: '400px',
                }}
              >
                <Chart data={data} axes={axes} />
              </div>
            </Card>
          </Col> */}
        </Row>
        {/* <Row style={{ padding: '10px' }} gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <ListDashboardClient />
          </Col>
          <Col xs={24} md={12}>
            <Card
              style={{
                borderRadius: '12px',
                width: '100%',
                height: '500px',
                backgroundColor: 'white',
                fontSize: 20,
                color: 'white',
                boxShadow: `1px 3px 10px ${GREY}`,
              }}
              className="box"
            >
              <Chart1 />
            </Card>
          </Col>
        </Row> */}
        {/* <Row style={{ padding: '12px' }} gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card
              style={{
                borderRadius: '12px',
                width: '100%',
                height: '150px',
                backgroundColor: '#00A65A',
                fontSize: 20,
                color: 'white',
                boxShadow: `1px 3px 10px ${GREY}`,
              }}
              className="box"
            >
              <strong>Unique Visitors</strong>
              <br />

              <div>
                <h1 style={{ float: 'right', fontSize: '60px', color: '#bfffe1' }}>
                  {' '}
                  <ShoppingOutlined />{' '}
                </h1>
              </div>

              <h6>
                <a href="#" style={{ color: 'white' }}>
                  More Info <DoubleRightOutlined />
                </a>{' '}
              </h6>
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card
              style={{
                borderRadius: '12px',
                width: '100%',
                height: '150px',
                backgroundColor: '#00A65A',
                fontSize: 20,
                color: 'white',
                boxShadow: `1px 3px 10px ${GREY}`,
              }}
              className="box"
            >
              <strong>Up Coming Updates</strong>
              <br />
              <div>
                <h1 style={{ float: 'right', fontSize: '60px', color: '#bfffe1' }}>
                  {' '}
                  <BarChartOutlined />{' '}
                </h1>
              </div>

              <h6>
                <a href="#" style={{ color: 'white' }}>
                  More Info <DoubleRightOutlined />
                </a>{' '}
              </h6>
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default Dashboard;
