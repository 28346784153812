import {
  Layout,
  Dropdown,
  Input,
  Col,
  Row,
  Tooltip,
  Button,
  Badge,
  Avatar,
  notification,
} from "antd";
import { AudioOutlined, UserOutlined, BellOutlined } from "@ant-design/icons";
import DropDownMenu from "components/DropDownMenu";

const { Header } = Layout;
const { Search } = Input;
const GREY = "#9E9E9E";

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const onSearch = (value) => console.log(value);
const openNotification = () => {
  const args = {
    message: "Notification Title",
    description:
      "I will never close automatically. This is a purposely very very long description that has many many characters and words.",
    duration: 0,
  };
  notification.open(args);
};
function AppHeader() {
  return (
    <Header className="app-header container">
      <Row className="app-header-row" align="middle">
        <Col style={{ paddingTop: "15px" }}>
          <Search
            placeholder="input search text"
            allowClear
            onSearch={onSearch}
            style={{ width: 400 }}
          />
        </Col>
        <Col flex="auto"></Col>
        <Col>
          <span style={{ padding: "7px" }}></span>
          <Dropdown overlay={<DropDownMenu />}>
            <span style={{ padding: "7px" }}>
              <Tooltip>
                <Button
                  type="success"
                  shape="circle"
                  icon={<UserOutlined />}
                  style={{
                    background: "#ddbc6b",
                    border: "2px solid #ddbc6b",
                    boxShadow: `1px 2px 5px ${GREY}`,
                  }}
                />
              </Tooltip>
            </span>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}

export default AppHeader;
