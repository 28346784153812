import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createAdminUser = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/user/register`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .send(payload);
};

export const editAdminUser = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/user/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .send(payload);
};

export const listAdminUsers = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/user/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};

export const deleteAdminUser = async (payload) => {
  return await superagent
    .delete(`${BASE_URL}/user/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const activeGender = async () => {
  return await superagent
    .get(`${BASE_URL}/gender/active`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};
