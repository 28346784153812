import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'containers/AppLogin/action';
import { activeClient } from 'services/client';

import {
  listClient,
  addClient,
  editClient,
  listClientUser,
  addClientUser,
  editClientUser,
  deleteClientUser,
} from 'services/attribute';
import {
  ADD_CLIENT,
  ADD_CLIENT_USER,
  EDIT_CLIENT,
  DELETE_CLIENT,
  LIST_CLIENT,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
  LIST_CLIENT_USER,
  EDIT_CLIENT_USER,
  DELETE_CLIENT_USER,
  CLEAR_CLIENT_USER_LIST,
  ACTIVE_ALL_CLIENT,
} from './constant';
import {
  listClient as listClientAction,
  listClientSuccess,
  listClientError,
  toggleDrawer,
  toggleDeleteModal,
  addClientSuccess,
  addClientError,
  editClientSuccess,
  editClientError,
  deleteClientSuccess,
  listClientUser as listClientUserAction,
  listClientUserSuccess,
  listClientUserError,
  clearClientUserList,
  addClientUserSuccess,
  addClientUserError,
  editClientUserSuccess,
  editClientUserError,
  deleteClientUserSuccess,
  deleteClientUserError,
  activeAllClientSuccess,
  activeAllClientError,
} from './action';

export function* listClientSaga(action) {
  try {
    const res = yield call(listClient, action.payload);
    const clientData = JSON.parse(res.text);
    const { clients, total } = clientData.data;
    yield put(
      listClientSuccess({
        total,
        clients: clients.map((item) => ({
          ...item,
          key: item.client_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listClientError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listClientError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addClientSaga(action) {
  try {
    const res = yield call(addClient, action.payload);
    const client = JSON.parse(res.text);
    yield put(addClientSuccess(client.data));
    yield put(toggleDrawer());
    yield put(listClientAction({ page: 1, limit: 10 }));
    message.success(client.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addCategoryError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addCategoryError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addClientError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editClientSaga(action) {
  try {
    const res = yield call(editClient, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editClientSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listClientAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editClientError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editClientError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editClientError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* deleteCategorySaga(action) {
  try {
    // const res = yield call(deleteCategory, action.payload);
    // const userData = JSON.parse(res.text);
    yield put(toggleDeleteModal());
    yield put(deleteClientSuccess());
    // yield call(listCategorySaga);
    // message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(deleteCategoryError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(deleteCategoryError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export function* clearClientUserListSaga(action) {
  put(clearClientUserList());
}

export function* listClientUserSaga(action) {
  try {
    const res = yield call(listClientUser, action.payload);
    const clientData = JSON.parse(res.text);
    const { clientUsers } = clientData.data;
    yield put(
      listClientUserSuccess(
        clientUsers.map((item) => ({
          ...item,
          key: item.clientuserid,
        })),
      ),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listClientUserError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listClientUserError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addClientUserSaga(action) {
  try {
    const res = yield call(addClientUser, action.payload);
    const client = JSON.parse(res.text);
    message.success(client.data.message);
    yield put(addClientUserSuccess(client.data));
    yield put(listClientUserAction({ client_id: action.payload.client_id }));
  } catch (err) {
    // console.log(err);
    if (_.isEqual(err.status, 401)) {
      yield put(addClientUserError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addClientUserError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addClientUserError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editClientUserSaga(action) {
  try {
    const res = yield call(editClientUser, action.payload);
    const client = JSON.parse(res.text);
    yield put(editClientUserSuccess(client.data));
    yield put(listClientUserAction({ client_id: action.payload.client_id }));
    message.success(client.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editClientUserError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editClientUserError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editClientUserError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* deleteClientUserSaga(action) {
  try {
    const res = yield call(deleteClientUser, { clientuserid: action.payload.clientuserid });
    const client = JSON.parse(res.text);
    yield put(deleteClientUserSuccess(client.data));
    yield put(listClientUserAction({ client_id: action.payload.client_id }));
    message.success(client.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(deleteClientUserError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(deleteClientUserError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(deleteClientUserError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* activeallClientsSaga(action) {
  try {
    const res = yield call(activeClient);
    const clientData = JSON.parse(res.text);
    console.log(clientData);
    yield put(activeAllClientSuccess(clientData.data.allclients));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(activeAllClientError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(activeAllClientError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
      yield put(activeAllClientError(err.response.text));
    }
  }
}

export default function* clientSaga() {
  yield takeLatest(LIST_CLIENT, listClientSaga);
  yield takeEvery(ADD_CLIENT, addClientSaga);
  yield takeEvery(EDIT_CLIENT, editClientSaga);
  yield takeEvery(LIST_CLIENT_USER, listClientUserSaga);
  yield takeEvery(CLEAR_CLIENT_USER_LIST, clearClientUserListSaga);
  yield takeEvery(ADD_CLIENT_USER, addClientUserSaga);
  yield takeEvery(EDIT_CLIENT_USER, editClientUserSaga);
  yield takeEvery(DELETE_CLIENT_USER, deleteClientUserSaga);
  yield takeEvery(ACTIVE_ALL_CLIENT, activeallClientsSaga);

  // yield takeEvery(DELETE_CATEGORY, deleteRolesSaga);
  // yield takeEvery(TOGGLE_CATEGORY, toggleDrawerSaga);
  // yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
