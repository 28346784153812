import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppLogin from "containers/AppLogin";
import AppLayout from "containers/AppLayout";
import PrivateRoute from "containers/PrivateRoute";
import Dashboard from "containers/Dashboard";
import Category from "containers/Category";
import Client from "containers/Client";
import Users from "containers/Users";
import Role from "containers/Role";
import Responsibilities from "containers/Responsibilities";
import Invoices from "containers/Invoices";
import Orders from "containers/Orders";
import Unauthorized from "components/Unauthorized";
import Product from "containers/Product";
import InvoiceSetting from "containers/InvoiceSettting/Index";
import WelcomeMessage from "containers/Message";
import Provider from "containers/Provider";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true}>
          <AppLogin />
        </Route>
        <AppLayout>
          <Route
            component={({ match }) => (
              <div>
                <PrivateRoute
                  path="/invoices"
                  component={Invoices}
                  tab={"USER_INVOICE_MANAGE_SUBTAB"}
                />

                <PrivateRoute
                  path="/Service"
                  component={Product}
                  tab={"PRODUCT_PROD_MANAGE_SUBTAB"}
                />

                <PrivateRoute
                  path="/categories"
                  component={Category}
                  tab={"PRODUCT_CATEGORY_MANAGE_SUBTAB"}
                />

                <PrivateRoute
                  path="/clients"
                  component={Client}
                  tab={"USER_CLIENT_MANAGE_SUBTAB"}
                />

                <PrivateRoute
                  path="/invoicesettings"
                  component={InvoiceSetting}
                  tab={"SETTING_INVOICE_SUBTAB"}
                />

                <PrivateRoute
                  path="/message"
                  component={WelcomeMessage}
                  tab={"MESSAGE_SUBTAB"}
                />
                <PrivateRoute
                  path="/orders"
                  component={Orders}
                  tab={"USER_ORDER_MANAGE_SUBTAB"}
                />
                <PrivateRoute
                  path="/role"
                  component={Role}
                  tab={"ACL_ROLE_MANAGE_SUBTAB"}
                />
                <PrivateRoute
                  path="/responsibilities"
                  component={Responsibilities}
                  tab={"ACL_RESPONSIBILITIES_MANAGE_SUBTAB"}
                />
                <PrivateRoute
                  path="/users"
                  component={Users}
                  tab={"USER_OPEN_MANAGE_SUBTAB"}
                />
                <PrivateRoute
                  path="/dashboard"
                  component={Dashboard}
                  tab={"APP_DASHBOARD"}
                />
                <PrivateRoute
                  path="/providers"
                  component={Provider}
                  tab={"ADD_PROVIDER_SUBTAB"}
                />

                <PrivateRoute
                  path="/unauthorized"
                  component={Unauthorized}
                  tab={null}
                />
              </div>
            )}
          />
        </AppLayout>
      </Switch>
    </Router>
  );
}

export default App;
