import React, { useEffect, useState } from "react";
import Statistics from "color-statistics";
import { Card, Col, Row } from "antd";
import {
  BarChartOutlined,
  DoubleRightOutlined,
  ShoppingOutlined,
  StopOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import reducer, { initialState } from "containers/Dashboard/reducer";
import saga from "containers/Dashboard/saga";
import { useSelector, useDispatch } from "react-redux";
import { clientState } from "containers/Dashboard/action";
import { Link } from "react-router-dom";

const GREY = "black";

function Statisticss() {
  useInjectReducer({ key: "dashboard", reducer });
  useInjectSaga({ key: "dashboard", saga });
  const dispatch = useDispatch();
  const dashboardState = useSelector(
    (state) => state.dashboard || initialState
  );
  useEffect(() => {
    dispatch(clientState());
  }, []);
  return (
    <div>
      <Row style={{ padding: "10px" }} gutter={[16, 16]}>
        <Col xs={24} md={6} stylye={{ padding: "10px" }}>
          <Card
            style={{
              borderLeft: "10px solid #ddbc6b",
              borderRadius: "12px",
              width: "100%",
              height: "150px",
              fontSize: 20,
              color: "white",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong style={{ color: "#2a2a2a" }}>New Clients</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", color: "#ddbc6b" }}>
              {" "}
              <UserAddOutlined />{" "}
            </h1>
            <h1>{dashboardState?.clientStats?.new_clients || "Loading..."}</h1>

            <h6>
              <Link to="/clients" style={{ color: "#ddbc6b" }}>
                More Info <DoubleRightOutlined />
              </Link>{" "}
            </h6>
          </Card>
        </Col>

        <Col xs={24} md={6} stylye={{ padding: "10px" }}>
          <Card
            style={{
              borderLeft: "10px solid #ddbc6b",
              borderRadius: "12px",
              width: "100%",
              height: "150px",
              fontSize: 20,
              color: "#fff",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong style={{ color: "#2a2a2a" }}>Active Clients</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", color: "#ddbc6b" }}>
              {" "}
              <BarChartOutlined />{" "}
            </h1>
            <h1>
              {dashboardState?.clientStats?.active_clients || "Loading..."}
            </h1>
            <h6>
              <Link to="/clients" style={{ color: "#ddbc6b" }}>
                More Info <DoubleRightOutlined />
              </Link>{" "}
            </h6>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card
            style={{
              borderLeft: "10px solid #ddbc6b",
              borderRadius: "12px",
              width: "100%",
              height: "150px",
              fontSize: 20,
              color: "white",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong style={{ color: "#2a2a2a" }}>Service Subscribe</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", color: "#ddbc6b" }}>
              {" "}
              <ShoppingOutlined />{" "}
            </h1>
            <h1>
              {dashboardState?.clientStats?.products_subscribed || "Loading..."}
            </h1>

            <h6>
              <Link to="/orders" style={{ color: "#ddbc6b" }}>
                More Info <DoubleRightOutlined />
              </Link>{" "}
            </h6>
          </Card>
        </Col>
        <Col xs={24} md={6} stylye={{ padding: "10px" }}>
          <Card
            style={{
              borderLeft: "10px solid #ddbc6b",
              borderRadius: "12px",
              width: "100%",
              height: "150px",
              fontSize: 20,
              color: "white",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong style={{ color: "#2a2a2a" }}>Suspended Clients</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", color: "#ddbc6b" }}>
              {" "}
              <StopOutlined />{" "}
            </h1>
            <h1>
              {dashboardState?.clientStats?.suspended_clients || "Loading..."}
            </h1>
            <h6>
              <Link to="/clients" style={{ color: "#ddbc6b" }}>
                More Info <DoubleRightOutlined />
              </Link>{" "}
            </h6>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Statisticss;
