import React, { memo } from 'react';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from 'containers/AppLogin/action';

const SessionModal = () => {
  const history = useHistory();
  const userState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logoutUser());
    localStorage.clear();
    history.push('/');
  };
  return (
    <>
      <Modal
        title={'Session Expired'}
        visible={userState.error}
        mask={true}
        maskClosable={false}
        closable={false}
        footer={
          <Button
            style={{ backgroundColor: '#ddbc6b', borderColor: '#ddbc6b', color: 'white' }}
            danger
            onClick={onLogout}
          >
            Login
          </Button>
        }
      >
        <p>{'Your session has expired. please relogin to continue'}</p>
      </Modal>
    </>
  );
};

export default memo(SessionModal);
