import * as Constant from './constant';

export const initialState = {
  totalCount: 0,
  data: [],
  activeallProducts: [],
  productAttribute: [],
  loading: false,
  error: false,
  drawer: false,
  deleteModal: false,
};

const attributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_PRODUCT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.total,
        data: action.payload.attributes,
        error: false,
      };
    case Constant.LIST_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
      case Constant.LIST_ACTIVE_PRODUCT:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case Constant.LIST_ACTIVE_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          activeallProducts: action.payload,
          error: false,
        };
      case Constant.LIST_ACTIVE_PRODUCT_ERROR:
        return {
          ...state,
          loading: false,
          activeallProducts: [],
          error: true,
        };
    case Constant.LIST_PRODUCT_ATTRIBUTE:
      return {
        ...state,
        loading: true,
        productAttribute: [],
        error: false,
      };
    case Constant.LIST_PRODUCT_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        productAttribute: action.payload,
        error: false,
      };
    case Constant.LIST_PRODUCT_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        productAttribute: [],
        error: false,
      };
    case Constant.ADD_PRODUCT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_PRODUCT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DELETE_PRODUCT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.SELECTED_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        productAttribute: action.payload,
      };
    case Constant.REMOVE_SELECTED_PRODUCT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        productAttribute: state.productAttribute.map(pa => pa.attributeid === action.payload.attributeid ?
          { ...pa, optionvalue: 'N/A' } :
          pa
        )
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default attributeReducer;
