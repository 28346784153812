import { useEffect, useState } from 'react';
import { Modal, Row, Col, Table, Divider, Skeleton, Image } from 'antd';
import { PAYMENT_METHOD, SUBSCRIPTION_PERIOD } from 'utils/constants';
import _ from 'lodash';
import { ProductCartTableConfig } from './ProductCartTableConfig';
// import { AddonCartTableConfig } from './AddonCartTableConfig';
import Logo from 'assets/Logo.png';

function OrderConfirmModal({ open, confirmOrder, values, orderState, }) {
  if (!orderState || !values || _.isEmpty(values) || _.isEmpty(orderState.drawerData)) {
    return null;
  }
  const prodCol = ProductCartTableConfig();
  // const addonCol = AddonCartTableConfig();
  const client = orderState.drawerData.clients.filter((d) =>
    _.isEqual(d.client_id, values.client_id),
  );
  const productCart = orderState.cartList;
  // const addonCart = orderState.addonCartList;
  const subscription_period_idx = parseInt(values.subscription_period);
  return (
    <Modal
      title="Order Confirmation"
      centered
      visible={open}
      onOk={() => confirmOrder(false, true)}
      onCancel={() => confirmOrder(false)}
      width={1000}
    >
      <Skeleton loading={orderState.loading} active={orderState.loading}>
        <Row>
          <Col>
            <Image src={Logo} alt="image_invoice" style={{ height:"100px", width: "250px" }}/>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <b style={{ fontSize: 20 }}>Accounts Department</b>
            {/* <h1 style={{ fontSize: 15 }}>
              79, P.E.C.H.S Block 2 Block 2 PECHS, Karachi, Karachi City, Sindh <br />
            </h1> */}
            {/* <h4>Phone: +923023338677</h4> */}
          </Col>
          <Col span={6}></Col>
          <Col span={4}></Col>
          <Col span={8}>
            <b style={{ fontSize: 15 }}>Client Name: </b>
            <b style={{ float: 'right', fontSize: 15 }}>{client[0].client_name} {client[0].last_name}</b> <br />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}></Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              <b>Invoice Status:</b>{' '}
              <span style={{ float: 'right' }}>{values.invoice_status ? 'Paid' : 'UnPaid'}</span>
            </h1>
          </Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              <b> Pro-Rated:</b>{' '}
              <span style={{ float: 'right' }}> {values.prorated_invoice ? 'Yes' : 'No'}</span>
            </h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}></Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              <b> Subscription Period:</b>{' '}
              <span style={{ float: 'right' }}>
                {SUBSCRIPTION_PERIOD[subscription_period_idx - 1]}
              </span>
            </h1>
          </Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              <b> Payment Method:</b>{' '}
              <span style={{ float: 'right' }}>{PAYMENT_METHOD[values.payment_method]}</span>
            </h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}></Col>
          <Col span={6}></Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              <b>Discount:</b> <span style={{ float: 'right' }}>{values.discount}</span>
            </h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}></Col>
          <Col span={6}></Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              {/* <b> Amount Payable:</b> <span style={{ float: 'right' }}>{}</span> */}
            </h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}></Col>
          <Col span={6}></Col>
          <Col span={6}>
            <h1 style={{ fontSize: 15 }}>
              {/* <b>Total Order:</b> <span style={{ float: 'right' }}></span> */}
            </h1>
          </Col>
        </Row>
        <br />
        <Divider>Order Products</Divider>
        <Row>
          <Col span={24}>
            <Table
              columns={prodCol}
              bordered
              size={'small'}
              dataSource={productCart}
              style={{ margin: '10px' }}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Table
              columns={addonCol}
              bordered
              size={'small'}
              dataSource={addonCart}
              style={{ margin: '10px' }}
            />
          </Col>
        </Row> */}
      </Skeleton>
    </Modal>
  );
}

export default OrderConfirmModal;
