export const LIST_ROLES_RESPONSIBILITIES = 'LIST_ROLES_RESPONSIBILITIES';
export const LIST_ROLES_RESPONSIBILITIES_SUCCESS = 'LIST_ROLES_RESPONSIBILITIES_SUCCESS';
export const LIST_ROLES_RESPONSIBILITIES_ERROR = 'LIST_ROLES_RESPONSIBILITIES_ERROR';

export const ADD_ROLE_RESPONSIBILITIES = 'ADD_ROLE_RESPONSIBILITIES';
export const ADD_ROLE_RESPONSIBILITIES_SUCCESS = 'ADD_ROLE_RESPONSIBILITIES_SUCCESS';
export const ADD_ROLE_RESPONSIBILITIES_ERROR = 'ADD_ROLE_RESPONSIBILITIES_ERROR';

export const LIST_ROLES = 'LIST_ROLES';
export const LIST_ROLES_SUCCESS = 'LIST_ROLES_SUCCESS';
export const LIST_ROLES_ERROR = 'LIST_ROLES_ERROR';
