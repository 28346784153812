import React, { useState, useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, InputNumber, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { addInvoice, editInvoice } from 'containers/Invoices/action';
import _ from 'lodash';

function AddInvoiceDrawer({
  initialState,
  selectedInvoice,
  editInvoice,
  resetPagination,
  roleAddResponsibility,
}) {
  const dispatch = useDispatch();
  const invoiceState = useSelector((state) => state.invoice || initialState);
  const [form] = Form.useForm();

  if (!_.isNil(selectedInvoice)) {
    form.setFieldsValue({
      startingprice: selectedInvoice.startingprice,
      tax: selectedInvoice.tax,
      payment_method: selectedInvoice.payment_method,
      invoice_date: selectedInvoice.invoice_date,
      due_date: selectedInvoice.due_date,
      client: selectedInvoice.client,
      payment_status: selectedInvoice.payment_status,
      description: selectedInvoice.description,
      terms: selectedInvoice.terms,
      status: _.isEqual(selectedInvoice.status, 1) ? true : false,
    });
  }

  const toggleInvoiceDrawer = (open = false) => {
    form.resetFields();
    editInvoice(null);
  };

  const handleInvoices = () => {
    form.validateFields().then((values) => {
      if (values && (_.isNil(values.tax) || _.isEmpty(values.tax))) values.tax = 'none';
      if (_.isNil(values.status) || values.status) values.status = 1;
      else values.status = 0;
      if (selectedInvoice) {
        dispatch(editInvoice({ invoiceid: selectedInvoice.invoiceid, ...values }));
      } else {
        dispatch(addInvoice(values));
      }
      resetPagination();
    });
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          style={{ marginRight: 10, backgroundColor: '#ddbc6b', borderColor: '#ddbc6b' }}
          size="large"
        >
          Print
        </Button>
      ) : (
        ''
      )}

      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleInvoiceDrawer()}
          size="large"
          style={{ backgroundColor: '#ddbc6b', borderColor: '#ddbc6b', color: 'white' }}
        >
          Add Invoice
        </Button>
      ) : (
        ''
      )}
      <Drawer
        title={selectedInvoice ? `Edit Invoice` : `Add Invoice`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleInvoiceDrawer()}
        visible={invoiceState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleInvoiceDrawer()}
              loading={invoiceState.loading}
              disabled={invoiceState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              loading={false}
              disabled={invoiceState.loading}
              onClick={() => handleInvoices()}
              style={{ backgroundColor: '#ddbc6b', borderColor: '#ddbc6b', color: 'white' }}
            >
              {selectedInvoice ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="startingprice"
                label="Price"
                initialValue={selectedInvoice?.startingprice}
                rules={[{ required: true, message: 'Please enter price' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tax"
                label="Tax"
                rules={[{ required: true, message: 'Please enter Tax' }]}
              >
                <Input placeholder="Please enter Tax" disabled={invoiceState.loading} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="payment_method" label="Payment Method">
                <Select placeholder="Payment Method" defaultValue="cash">
                  <Option value="cash">Cash</Option>
                  <Option value="bank_transfer">Bank Transfer</Option>
                  <Option value="credit_card">Credit Card</Option>
                  <Option value="paypal">PayPal</Option>
                  <Option value="paypro">PayPro</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item name="invoice_date" label="Invoice Date">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="due_date" label="Due Date">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="client"
                label="Client"
                rules={[{ required: false, message: 'Please enter client name' }]}
              >
                <Input placeholder="Please enter client name" disabled={invoiceState.loading} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="payment_status" label="Payment Status">
                <Select placeholder="Payment Status" defaultValue={true}>
                  <Option value={true}>Paid</Option>
                  <Option value={false}>unpaid</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: false, message: 'Please enter description' }]}
              >
                <Input.TextArea
                  placeholder="Please enter description"
                  rows={4}
                  allowClear={true}
                  disabled={invoiceState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="terms"
                label="Terms"
                rules={[{ required: false, message: 'Please enter terms' }]}
              >
                <Input.TextArea
                  placeholder="Please enter terms"
                  rows={4}
                  allowClear={true}
                  disabled={invoiceState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddInvoiceDrawer);
