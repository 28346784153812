import React, { useState, useEffect, memo } from 'react';
import { Modal } from 'antd';

const ConfirmModal = ({ toggle, text, title, onDelete, handleDeleteModalToggle, loading }) => {
  return (
    <>
      <Modal
        title={title}
        visible={toggle}
        onOk={() => onDelete(null)}
        onCancel={handleDeleteModalToggle}
        confirmLoading={loading}
      >
        <p>{loading ? 'Deleting...' :  text}</p>
      </Modal>
    </>
  );
};

export default memo(ConfirmModal);
