import { call, put, takeEvery, takeLatest, apply } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'containers/AppLogin/action';
import { listProduct, addProduct, editProduct, listProductAttribute } from 'services/attribute';
import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  LIST_PRODUCT,
  LIST_PRODUCT_ATTRIBUTE,
  SELECTED_PRODUCT_ATTRIBUTES,
  REMOVE_SELECTED_PRODUCT_ATTRIBUTES,
  TOGGLE_DRAWER,
  TOGGLE_DELETE_MODAL,
  LIST_ACTIVE_PRODUCT,
} from './constant';
import {
  listProduct as listProductAction,
  listProductSuccess,
  listProductError,
  listProductAttributeSuccess,
  listProductAttributeError,
  addProductSuccess,
  addProductError,
  selectedProductAttributes,
  removeSelectedProductAttributesSuccess,
  editProductSuccess,
  editProductError,
  toggleDrawer,
  toggleDeleteModal,
  activeAllProductsSuccess,
  activeAllProductsError,
} from './action';
import { activeProduct } from 'services/client';

export function* listProductSaga(action) {
  try {
    const res = yield call(listProduct, action.payload);
    const productData = JSON.parse(res.text);
    console.log(productData)
    yield put(
      listProductSuccess({
        total: productData.products.total,
        attributes: productData.products.products.map((item) => ({
          ...item,
          key: item.productid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listProductError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listProductError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* activeallProductsSaga(action) {
  try {
    const res = yield call(activeProduct);
    const productData = JSON.parse(res.text);
    console.log(productData)
    yield put(activeAllProductsSuccess(productData.data.products));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(activeAllProductsError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(activeAllProductsError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
      yield put(activeAllProductsError(err.response.text));
    }
  }
}

export function* listProductAttributeSaga(action) {
  try {
    const res = yield call(listProductAttribute, action.payload);
    const productData = JSON.parse(res.text);
    yield put(listProductAttributeSuccess(
      productData.productAttributes.map((pattr, index) => ({ key: index, ...pattr })),
    ));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listProductAttributeError());;
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listProductAttributeError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addProductSaga(action) {
  try {
    const res = yield call(addProduct, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addProductSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listProductAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addProductError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addProductError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addProductError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editProductSaga(action) {
  try {
    const res = yield call(editProduct, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editProductSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listProductAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editProductError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editProductError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editProductError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* deleteAttributeSaga(action) {
  try {
    const res = yield call(deleteRole, action.payload);
    const userData = JSON.parse(res.text);
    yield put(toggleDeleteModal());
    yield put(deleteRoleSuccess(userData));
    yield call(listProduct);
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(deleteRoleError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(deleteRoleError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* selectedProductAttributeSaga(action) {
  put(selectedProductAttributes(action.payload));
}

export function* removeSelectedProductAttributesSaga(action) {
  yield put(removeSelectedProductAttributesSuccess(action.payload));
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* productSaga() {
  yield takeLatest(LIST_PRODUCT, listProductSaga);
  yield takeEvery(LIST_PRODUCT_ATTRIBUTE, listProductAttributeSaga);
  yield takeEvery(ADD_PRODUCT, addProductSaga);
  yield takeEvery(EDIT_PRODUCT, editProductSaga);
  // yield takeEvery(DELETE_PRODUCT, deleteAttributeSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
  yield takeEvery(SELECTED_PRODUCT_ATTRIBUTES, selectedProductAttributeSaga);
  yield takeEvery(REMOVE_SELECTED_PRODUCT_ATTRIBUTES, removeSelectedProductAttributesSaga);
  yield takeEvery(LIST_ACTIVE_PRODUCT, activeallProductsSaga);

}
