import React, { useState, useEffect, memo } from 'react';
import {
  Input,
  Form,
  Button,
  Drawer,
  Col,
  Row,
  Select,
  Table,
  InputNumber,
  Skeleton,
  Typography,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { activeCategory } from 'containers/Category/action';
import {
  addProduct,
  editProduct as editProductAction,
  removeSelectedProductAttributes,
  listProductAttribute,
} from 'containers/Product/action';
// import AttributeSelectionModal from 'components/AttributeSelectionModal';
// import { selectedProductAttributeConfig } from './ProductAttributeDrawerConfig';
import _ from 'lodash';

const { Title } = Typography;

function AddProductDrawer({
  initialState,
  selectedProduct,
  editProduct,
  categoryInitialState,
  roleAddResponsibility,
  resetPagination,
}) {
  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product || initialState);
  const categoryState = useSelector((state) => state.category || categoryInitialState);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [attribute, setAttribute] = useState(null);
  const [form] = Form.useForm();
  if (!_.isNil(selectedProduct)) {
    form.setFieldsValue({
      productname: selectedProduct.productname,
      productdescription: selectedProduct.productdescription,
      productcategoryid: selectedProduct.productcategoryid,
      isfeatured: selectedProduct.isfeatured ? true : false,
      isfeatureddescription: selectedProduct.isfeatureddescription,
      isactive: selectedProduct.isactive,
      startingprice: selectedProduct.startingprice,
    });
  } else {
    form.setFieldsValue({
      isfeatured: false,
      isactive: true,
    });
  }
  useEffect(() => {
    if (productState.drawer) {
      dispatch(activeCategory());
    }
  }, [categoryState.drawer]);

  const productSelectedAttributes = (items) => setSelectedAttributes(items);

  const toggleProductDrawer = (open = false) => {
    form.resetFields();
    editProduct(null);
  };
  const handleProduct = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;
      values.isfeatured = _.isNil(values.isfeatured) || !values.isfeatured ? false : true;

      if (selectedProduct) {
        const { productid } = selectedProduct;
        const newProduct = {
          productid,
          ...values,
          attributes: selectedAttributes.map((p, index) => ({
            attributeid: p.attributeid,
            optionvalue: p.optionvalue,
            sort_order: index + 1,
          })),
        };
        dispatch(editProductAction(_.omitBy(newProduct, _.isNil)));
      } else {
        const newProduct = {
          ...values,
          attributes: selectedAttributes.map((p, index) => ({
            attributeid: p.attributeid,
            optionvalue: p.optionvalue,
            sort_order: index + 1,
          })),
        };
        dispatch(addProduct(_.omitBy(newProduct, _.isNil)));
      }
    });
    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          type="primary"
          onClick={() => toggleProductDrawer()}
          size="medium"
          style={{ marginRight: 10, backgroundColor: '#ddbc6b', border: '#ddbc6b', color: 'white' }}
        >
          Add Service
        </Button>
      ) : (
        ''
      )}
      <Drawer
        title={selectedProduct ? 'Edit Service' : 'Add Service'}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleProductDrawer()}
        visible={productState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleProductDrawer()}
              loading={productState.loading}
              disabled={productState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleProduct()}
              type="primary"
              loading={false}
              disabled={productState.loading}
              style={{ backgroundColor: '#ddbc6b', borderColor: '#ddbc6b', color: 'white' }}
            >
              {selectedProduct ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={categoryState.loading && productState.loading}
          active={categoryState.loading && productState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productname"
                  label="Product Name"
                  rules={[{ required: true, message: 'Please enter product name' }]}
                >
                  <Input placeholder="Please enter product name" disabled={productState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productdescription"
                  label="Product Description"
                  rules={[{ required: true, message: 'Please enter product description' }]}
                >
                  <Input
                    placeholder="Please enter product description"
                    disabled={productState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productcategoryid"
                  label="Select Product Category"
                  initialValue={selectedProduct?.productcategoryid}
                  rules={[
                    {
                      required: false,
                      message: 'Please select product category',
                    },
                  ]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryState.activeCategories.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="startingprice"
                  label="Price"
                  initialValue={selectedProduct?.startingprice}
                  rules={[{ required: true, message: 'Please enter price' }]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="isfeatured" label="Featured">
                  <Select placeholder="Status" defaultValue={false}>
                    <Option value={true}>Featured</Option>
                    <Option value={false}>Not Featured</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="isfeatureddescription"
                  label="Featured Description"
                  rules={[{ required: false, message: 'Please enter featured description' }]}
                >
                  <Input.TextArea
                    placeholder="Please enter featured description"
                    rows={4}
                    allowClear={true}
                    disabled={productState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {/* <AttributeSelectionModal
            selectedProduct={selectedProduct}
            productState={productState}
            productSelectedAttributes={productSelectedAttributes}
          /> */}
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddProductDrawer);
