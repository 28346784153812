import { Table, Input, Button, Space, Typography, Tag } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const CategoryTableConfig = (
  handleSearch,
  handleReset,
  onDelete,
  editProductCategory,
  responsibilities,
) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    // {
    //   title: 'ID',
    //   dataIndex: 'childcategoryid',
    //   key: 'childcategoryid',
    //   width: '3%',
    // },
    {
      title: 'Category',
      dataIndex: 'childcategoryname',
      key: 'childcategoryname',
      width: '10%',
    },
    // {
    //   title: 'Parent Category',
    //   dataIndex: 'parentcategoryname',
    //   key: 'parentcategoryname',
    //   width: '10%',
    // },
    {
      title: 'Status',
      dataIndex: 'isenabled',
      key: 'isenabled',
      width: '10%',
      render: (isenabled) => {
        return (
          <Tag color={_.isEqual(isenabled, 1) ? 'green' : 'red'}>
            {_.isEqual(isenabled, 1) ? 'Active' : 'In Active'}
          </Tag>
        );
      },
    },
    {
      title: 'Hompage Category',
      dataIndex: 'ishomepagecat',
      key: 'ishomepagecat',
      width: '10%',
      render: (ishomepagecat) => {
        return <Tag color={ishomepagecat ? 'green' : 'red'}>{ishomepagecat ? 'Yes' : 'No'}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <EyeOutlined style={{ color: '#0D8616', cursor: 'pointer', fontSize: '20px' }} />
              {_.includes(responsibilities, 'PRODUCT_CATEGORY_EDIT_BUTTON') ? (
                <EditOutlined
                  style={{ color: '#0D4B86', cursor: 'pointer', fontSize: '20px' }}
                  onClick={() => editProductCategory(item)}
                />
              ) : (
                ''
              )}
              {/* {_.includes(responsibilities, 'PRODUCT_CATEGORY_DELETE_BUTTON') ? (
                <DeleteOutlined
                  style={{ color: '#F52A0E', cursor: 'pointer', fontSize: '20px' }}
                  onClick={() => onDelete(item)}
                />
              ) : (
                ''
              )} */}
            </Space>
          </div>
        );
      },
    },
  ];
};
