import * as Constant from './constant';

export const initialState = {
  data: [],
  superAdminResponsibilities: [],
  userRoleResponsibilities: [],
  loading: false,
  error: false,
};

const userResponsibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_ROLES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case Constant.LIST_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.LIST_ROLES_RESPONSIBILITIES:
      return {
        ...state,
        loading: true,
        userRoleResponsibilities: [],
        superAdminResponsibilities: [],
        error: false,
      };
    case Constant.LIST_ROLES_RESPONSIBILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoleResponsibilities: action.payload.userRoleResponsibilities,
        superAdminResponsibilities: action.payload.superAdminResponsibilities,
        error: false,
      };
    case Constant.LIST_ROLES_RESPONSIBILITIES_ERROR:
      return {
        ...state,
        loading: false,
        superAdminResponsibilities: [],
        userRoleResponsibilities: [],
        error: true,
      };
    case Constant.ADD_ROLE_RESPONSIBILITIES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_ROLE_RESPONSIBILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_ROLE_RESPONSIBILITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default userResponsibilityReducer;
