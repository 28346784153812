import * as Constant from './constant';

export const initialState = {
  data: [],
  loading: false,
  error: false,
  drawer: false,
  deleteModal: false,
};

const invoiceSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_INVOICE_SETTING:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case Constant.LIST_INVOICE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case Constant.LIST_INVOICE_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
      };
    case Constant.EDIT_INVOICE_SETTING:
      return {
        ...state,
        loading: true,
      };
    case Constant.EDIT_INVOICE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Constant.EDIT_INVOICE_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default invoiceSettingReducer;
